<template>
  <b-modal
  id="edit-exam-modal"
  hide-header
  hide-footer
  centered
  @hidden="closeEditExamModal"
  >
    <div class="header">
      <div class="header-content">
        <p>Editar documento anexado</p>
        <Close class="close-icon" @click="closeEditExamModal" />
      </div>
    </div>

    <div class="body">
      <div class="body-content">
        <div class="container">
          <p class="title-body-content">Nome arquivo</p>
          <input class="input-name-file" type="text" v-model="form.custom_name" spellcheck="false">

          <div class="file-content">
            <p class="title-file-content">Tipo de documento</p>

            <div class="file-type">
              <input class="input-file-type" type="radio" name="type" id="intern" value="intern" v-model="form.type_id">
              <p>Realizado internamente</p>

              <input class="input-file-type" type="radio" name="type" id="extern" value="extern" v-model="form.type_id">
              <p>Em outra instituição</p>
            </div>
          </div>
        </div>

        <div v-if="form.file" class="show-file">
          <div class="show-file-content">
            <div class="data-file">
              <Pdf v-if="getFileType(form.file_id) == 'pdf' || form.type == 'application/pdf'" class="pdf-icon" />
              <Jpg v-else-if="['jpg','jpeg', 'jps'].includes(getFileType(form.file_id)) || form.type == 'image/jpeg'" class="jpg-icon" />
              <Png v-else-if="getFileType(form.file_id) == 'png' || form.type == 'image/png'" class="png-icon" />

              <p id="data-file-text">{{ form.custom_name + '.' + getFileType(form.file_id) }}</p>
            </div>

            <Close class="close-icon" @click="removeFile" />
          </div>
        </div>

        <div v-else class="set-file" @drop="dragFile" @dragover.prevent @drop.prevent>
          <div class="set-file-content">
            <p class="set-file-content-text">Arraste o arquivo aqui</p>

            <div class="middle-set-file-content">
              <div class="middle-set-file-content-detail"></div>
              <p class="middle-set-file-content-text">ou</p>
              <div class="middle-set-file-content-detail"></div>
            </div>

            <label
            for="set-file-content-input"
            class="set-file-content-input"
            >
            Anexar arquivo
            </label>

            <input
            type="file"
            name="set-file-content-input"
            id="set-file-content-input"
            accept=".jpg, .png, .pdf"
            @change="addFile"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-content">
        <button @click="updateFile">Salvar documento</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Jpg from '@/assets/icons/jpg.svg'
import Png from '@/assets/icons/png.svg'

export default {
  name: "EditExamModal",
  components: {
    Close,
    Pdf,
    Jpg,
    Png,
  },
  props: {
    attendanceExamFile: Object,
    getAllExams: { type: Function, require: true }
  },
  data() {
    return {
      form: {},
    }
  },
  watch: {
    attendanceExamFile:function(value) {
      this.form = value
    },
  },
  methods: {
    getFileType(fileId) {
      const parts = fileId.split('_')
      return parts[parts.length - 1]
    },
    dragFile(e) {
      const file = e.dataTransfer.files[0];
      const fileType = file.type;
      this.fileExtension = fileType.split('/')[1];
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : '';
      if (![
        'pdf','jpg','jpeg','png', 'jps',
        'application/pdf','image/jpg',
        'image/jpeg','image/png', 'tiff'
      ].includes(fileType || nameExt)) {
        return this.$toast.error('Formato de arquivo não suportado!');
      }
      this.form = {
        ...this.form,
        file: e.dataTransfer.files[0]
      }
    },
    closeEditExamModal() {
      this.form = {}
      this.getAllExams()
      this.$bvModal.hide('edit-exam-modal')
    },
    addFile(e) {
      this.form = {
        ...this.form,
        file: e.target.files[0]
      }
    },
    removeFile() {
      this.form = {
        ...this.form,
        file: null
      }
    },
    updateFile() {
      const files = this.form.file || null
      const fileName = this.form.custom_name
      const fileType = this.form.type_id

      const dataForm = new FormData();
      dataForm.append(`file`, files);
      dataForm.append(`fileName`, fileName)
      dataForm.append(`type`, fileType)

      const isLoading = this.$loading.show();
      this.api
        .updateAttendanceFile(this.form.id, dataForm)
        .then(async res => {
          this.$toast.success('Atualizado com sucesso!');
          this.closeEditExamModal()
        })
        .finally(() => isLoading.hide());
    },
  }
}
</script>

<style lang="scss">
#edit-exam-modal {
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 536px;

    .modal-body {
      padding: 0;

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }

          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            padding: 24px;

            .title-body-content {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
            }

            .input-name-file {
              background: #FFFFFF;
              border: 1px solid #C6CEEB;
              box-sizing: border-box;
              border-radius: 8px;
              height: 56px;
              outline: none;
              text-indent: 16px;
              color: var(--type-active);
              margin: 8px 0 24px 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
            }

            .file-content {
              display: flex;
              flex-direction: column;

              .title-file-content {
                color: var(--dark-blue);
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
              }

              .file-type {
                display: flex;
                align-items: center;
                margin: 8px 0 0 0;

                .input-file-type {
                  height: 24px;
                  width: 24px;
                  margin-right: 12px;
                  box-sizing: border-box;
                  border-radius: 100px;
                  cursor: pointer;
                }

                p + input {
                  margin-left: 24px;
                }
              }
            }
          }

          .show-file {
            border-top: 1px solid #C6CEEB;
            border-bottom: 1px solid #C6CEEB;

            .show-file-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin: 24px;

              .data-file {
                display: flex;
                flex-direction: row;
                align-items: center;

                .pdf-icon {
                  width: 42px !important;
                  height: 42px !important;
                }

                .jpg-icon {
                  width: 42px !important;
                  height: 42px !important;
                }

                .png-icon {
                  width: 42px !important;
                  height: 42px !important;
                }

                #data-file-text {
                  margin-left: 16px;
                  width: 40ch;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .close-icon {
                height: 24px;
                width: 24px;
                fill: var(--neutral-500);
              }
            }
          }

          .set-file {
            margin: 0 24px;

            .set-file-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 135px;
              border: 1px dashed #305BF2;
              border-radius: 8px;

              .set-file-content-text {
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
              }

              .middle-set-file-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 12px 0;

                .middle-set-file-content-detail {
                  width: 54px;
                  height: 2px;
                  background: var(--neutral-300);
                  border-radius: 16px;
                }

                .middle-set-file-content-text {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 130%;
                  padding: 0 14px;
                }
              }

              .set-file-content-input {
                width: auto;
                height: auto;
                padding: 8px 16px;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                color: var(--neutral-000);
                border-radius: 8px;
                background: var(--blue-700);
                cursor: pointer;
              }

              #set-file-content-input {
                display: none;
              }
            }
          }
        }
      }

      .footer {
        .footer-content {
          display: flex;
          justify-content: right;
          margin: 24px;

          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            border-radius: 8px;
            background-color: var(--blue-500);
            color: var(--neutral-000);
          }
        }
      }
    }
  }
}

</style>


